import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { CategoryStyles } from './BlogPostList';

const CategoriesStyles = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  justify-content: center;
  a {
    background-image: var(--primary-grad);
    padding: 1rem 2rem;
    font-size: 1.4rem;
    border-radius: 3rem;
    margin: 0.5rem;

    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    .count {
      padding: 2px 5px;
    }
    &[aria-current='page'] {
      background-image: var(--secondary-grad);
    }
  }
`;

function countBlogPostsInCategories(blogPosts) {
  const counts = blogPosts
    .map((blogPost) => blogPost.categories)
    .flat()
    .reduce((acc, category) => {
      const existingCategory = acc[category.id];
      if (existingCategory) {
        existingCategory.count += 1;
      } else {
        acc[category.id] = {
          id: category.id,
          name: category.name,
          slug: category.slug,
          count: 1,
        };
      }
      return acc;
    }, {});

  const sortedCounts = Object.values(counts).sort((a, b) => b.count - a.count);
  return sortedCounts;
}

export default function BlogCategoriesFilter() {
  const { blogPosts } = useStaticQuery(graphql`
    query {
      blogPosts: allSanityBlogPost {
        nodes {
          categories {
            name
            id
            slug {
              current
            }
          }
        }
      }
    }
  `);
  const categoriesWithCounts = countBlogPostsInCategories(blogPosts.nodes);

  return (
    <CategoriesStyles>
      <Link to="/blog">
        <CategoryStyles>
          <h3 className="name">All</h3>
          <h3 className="count">{blogPosts.nodes.length}</h3>
        </CategoryStyles>
      </Link>
      {categoriesWithCounts.map((category) => (
        <Link to={`/blog/${category.slug.current}`} key={category.id}>
          <CategoryStyles>
            <h3 className="name">{category.name}</h3>
            <h3 className="count">{category.count}</h3>
          </CategoryStyles>
        </Link>
      ))}
    </CategoriesStyles>
  );
}
