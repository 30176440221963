import React from 'react';
import { graphql } from 'gatsby';

import SanityImage from 'gatsby-plugin-sanity-image';
import BlogPostList from '../components/BlogPostList';
import BlogCategoriesFilter from '../components/BlogCategoriesFilter';

import SEO from '../components/SEO';

export default function BlogPage({ data, pageContext }) {
  const blogPosts = data.blogPosts.nodes;
  return (
    <>
      <SEO title={pageContext.name ? `${pageContext.name} - Blog` : `Blog`} />
      <h1>Blog</h1>
      {/* <BlogCategoriesFilter /> */}
      <BlogPostList blogPosts={blogPosts} />
    </>
  );
}

export const query = graphql`
  query BlogPostQuery($slug: String) {
    blogPosts: allSanityBlogPost(
      filter: {
        categories: { elemMatch: { slug: { current: { eq: $slug } } } }
      }
    ) {
      nodes {
        name
        slug {
          current
        }
        categories {
          name
          id
          slug {
            current
          }
        }
        image {
          ...ImageWithPreview
          alt
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        id
      }
    }
  }
`;
